/**
 * This file was automatically generated by @cosmwasm/ts-codegen@0.35.3.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { Coin, StdFee } from '@cosmjs/amino'
import {
  CosmWasmClient,
  ExecuteResult,
  SigningCosmWasmClient,
} from '@cosmjs/cosmwasm-stargate'

import { Addr, InfoResponse } from '@dao-dao/types'
import {
  ArrayOfVotingVault,
  Config,
  TotalPowerAtHeightResponse,
  VotingPowerAtHeightResponse,
} from '@dao-dao/types/contracts/NeutronVotingRegistry'

export interface NeutronVotingRegistryReadOnlyInterface {
  contractAddress: string
  dao: () => Promise<Addr>
  config: () => Promise<Config>
  votingVaults: ({ height }: { height?: number }) => Promise<ArrayOfVotingVault>
  votingPowerAtHeight: ({
    address,
    height,
  }: {
    address: string
    height?: number
  }) => Promise<VotingPowerAtHeightResponse>
  totalPowerAtHeight: ({
    height,
  }: {
    height?: number
  }) => Promise<TotalPowerAtHeightResponse>
  info: () => Promise<InfoResponse>
}
export class NeutronVotingRegistryQueryClient
  implements NeutronVotingRegistryReadOnlyInterface
{
  client: CosmWasmClient
  contractAddress: string

  constructor(client: CosmWasmClient, contractAddress: string) {
    this.client = client
    this.contractAddress = contractAddress
    this.dao = this.dao.bind(this)
    this.config = this.config.bind(this)
    this.votingVaults = this.votingVaults.bind(this)
    this.votingPowerAtHeight = this.votingPowerAtHeight.bind(this)
    this.totalPowerAtHeight = this.totalPowerAtHeight.bind(this)
    this.info = this.info.bind(this)
  }

  dao = async (): Promise<Addr> => {
    return this.client.queryContractSmart(this.contractAddress, {
      dao: {},
    })
  }
  config = async (): Promise<Config> => {
    return this.client.queryContractSmart(this.contractAddress, {
      config: {},
    })
  }
  votingVaults = async ({
    height,
  }: {
    height?: number
  }): Promise<ArrayOfVotingVault> => {
    return this.client.queryContractSmart(this.contractAddress, {
      voting_vaults: {
        height,
      },
    })
  }
  votingPowerAtHeight = async ({
    address,
    height,
  }: {
    address: string
    height?: number
  }): Promise<VotingPowerAtHeightResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      voting_power_at_height: {
        address,
        height,
      },
    })
  }
  totalPowerAtHeight = async ({
    height,
  }: {
    height?: number
  }): Promise<TotalPowerAtHeightResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      total_power_at_height: {
        height,
      },
    })
  }
  info = async (): Promise<InfoResponse> => {
    return this.client.queryContractSmart(this.contractAddress, {
      info: {},
    })
  }
}
export interface NeutronVotingRegistryInterface
  extends NeutronVotingRegistryReadOnlyInterface {
  contractAddress: string
  sender: string
  addVotingVault: (
    {
      newVotingVaultContract,
    }: {
      newVotingVaultContract: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  deactivateVotingVault: (
    {
      votingVaultContract,
    }: {
      votingVaultContract: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  activateVotingVault: (
    {
      votingVaultContract,
    }: {
      votingVaultContract: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
  updateConfig: (
    {
      owner,
    }: {
      owner: string
    },
    fee?: number | StdFee | 'auto',
    memo?: string,
    _funds?: Coin[]
  ) => Promise<ExecuteResult>
}
export class NeutronVotingRegistryClient
  extends NeutronVotingRegistryQueryClient
  implements NeutronVotingRegistryInterface
{
  client: SigningCosmWasmClient
  sender: string
  contractAddress: string

  constructor(
    client: SigningCosmWasmClient,
    sender: string,
    contractAddress: string
  ) {
    super(client, contractAddress)
    this.client = client
    this.sender = sender
    this.contractAddress = contractAddress
    this.addVotingVault = this.addVotingVault.bind(this)
    this.deactivateVotingVault = this.deactivateVotingVault.bind(this)
    this.activateVotingVault = this.activateVotingVault.bind(this)
    this.updateConfig = this.updateConfig.bind(this)
  }

  addVotingVault = async (
    {
      newVotingVaultContract,
    }: {
      newVotingVaultContract: string
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        add_voting_vault: {
          new_voting_vault_contract: newVotingVaultContract,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  deactivateVotingVault = async (
    {
      votingVaultContract,
    }: {
      votingVaultContract: string
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        deactivate_voting_vault: {
          voting_vault_contract: votingVaultContract,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  activateVotingVault = async (
    {
      votingVaultContract,
    }: {
      votingVaultContract: string
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        activate_voting_vault: {
          voting_vault_contract: votingVaultContract,
        },
      },
      fee,
      memo,
      _funds
    )
  }
  updateConfig = async (
    {
      owner,
    }: {
      owner: string
    },
    fee: number | StdFee | 'auto' = 'auto',
    memo?: string,
    _funds?: Coin[]
  ): Promise<ExecuteResult> => {
    return await this.client.execute(
      this.sender,
      this.contractAddress,
      {
        update_config: {
          owner,
        },
      },
      fee,
      memo,
      _funds
    )
  }
}
