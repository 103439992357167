import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import { DaoVotingVaultCardProps } from '@dao-dao/types'
import { formatPercentOf100 } from '@dao-dao/utils'

import { TooltipInfoIcon } from '../tooltip'

export const DaoVotingVaultCard = ({
  vault: { name, description, info },
  vaultVotingPowerPercent,
  walletVotingPowerPercent,
}: DaoVotingVaultCardProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={clsx(
        'relative flex flex-col justify-between overflow-hidden rounded-md border border-border-primary',
        !info.real && 'border-dashed'
      )}
    >
      {!info.real && (
        <div className="absolute top-[1rem] left-[-6.5rem] flex w-60 -rotate-45 flex-row items-center justify-center gap-1 bg-background-primary py-1.5 px-36">
          <p className="primary-text grow text-center text-xs font-bold text-text-primary">
            {t('title.virtual')}
          </p>
          <TooltipInfoIcon
            size="xs"
            title={t('info.virtualVotingVaultTooltip')}
          />
        </div>
      )}

      <div className="flex flex-col items-center gap-4 p-6 sm:p-10">
        {/* Name */}
        <p className="title-text px-4 text-center">
          {NAME_OVERRIDES[name] || name}
        </p>

        <p className="body-text px-4 text-center text-text-secondary">
          {info.real
            ? t('info.realVotingVaultDescription', {
                tokenSymbol: info.bondToken.symbol,
              })
            : DESCRIPTION_OVERRIDES[name] || description}
        </p>
      </div>

      <div className="flex flex-col gap-2 border-t border-border-interactive-disabled p-4">
        {/* Vault voting power */}
        <div className="flex flex-row flex-wrap items-center justify-between gap-x-2 gap-y-1">
          <div className="flex flex-row items-center gap-1">
            <p className="secondary-text">{t('info.vaultVotingPower')}</p>
            <TooltipInfoIcon
              size="xs"
              title={t('info.vaultVotingPowerTooltip')}
            />
          </div>

          <p
            className={clsx(
              'body-text font-mono',
              vaultVotingPowerPercent.loading && 'animate-pulse'
            )}
          >
            {vaultVotingPowerPercent.loading
              ? '...'
              : formatPercentOf100(vaultVotingPowerPercent.data)}
          </p>
        </div>

        {/* Wallet's voting power. Hide if undefined because no wallet. */}
        {(walletVotingPowerPercent.loading ||
          walletVotingPowerPercent.data !== undefined) && (
          <div className="flex flex-row flex-wrap items-center justify-between gap-x-2 gap-y-1">
            <div className="flex flex-row items-center gap-1">
              <p className="secondary-text">{t('info.yourVaultVotingPower')}</p>
              <TooltipInfoIcon
                size="xs"
                title={t('info.yourVaultVotingPowerTooltip')}
              />
            </div>

            <p
              className={clsx(
                'body-text font-mono',
                walletVotingPowerPercent.loading && 'animate-pulse'
              )}
            >
              {walletVotingPowerPercent.loading
                ? '...'
                : formatPercentOf100(walletVotingPowerPercent.data || 0)}
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

// Override titles and descriptions for a vault.
const NAME_OVERRIDES: Partial<Record<string, string>> = {
  'CREDITS VAULT': 'Credits Vault',
  'Investors Vault': 'Early Backers Vault',
  'Timewave Vesting Vault': 'Timewave Vault',
}
const DESCRIPTION_OVERRIDES: Partial<Record<string, string>> = {
  'Lockdrop Vault':
    'Voting power generated by the LP tokens locked during the Lockdrop phase of the launch event',
  'Investors Vault':
    'Voting power generated by locked/vested tokens of early backers.',
  'LP Vault':
    "Voting power generated by LP tokens from the Liquid Auction that were not locked at the launch event's Lockdrop.",
  'Grants SubDAO Vault':
    'Voting power generated by the vested grants team allocations.',
  'CREDITS VAULT': 'Voting power generated by the vested airdrop tokens.',
  'Timewave Vesting Vault':
    'Voting power generated by locked/vested tokens from Proposal N-19.',
}

export const DaoVotingVaultCardLoader = () => (
  <div className="flex animate-pulse flex-col justify-between rounded-md border border-border-primary">
    <div className="flex flex-col items-center gap-4 p-6 sm:p-10">
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <p className="title-text invisible px-4 text-center">Name</p>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <p className="body-text invisible px-4 text-center">Description</p>
    </div>

    <div className="flex flex-col gap-2 border-t border-border-interactive-disabled p-4">
      <div className="flex flex-row flex-wrap items-center justify-between gap-x-2 gap-y-1">
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <p className="secondary-text invisible">Voting power</p>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <p className="body-text invisible font-mono">0%</p>
      </div>

      <div className="flex flex-row flex-wrap items-center justify-between gap-x-2 gap-y-1">
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <p className="secondary-text invisible">Your voting power</p>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <p className="body-text invisible font-mono">0%</p>
      </div>
    </div>
  </div>
)
