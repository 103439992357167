/**
 * This file was automatically generated by @cosmwasm/ts-codegen@0.35.3.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run the @cosmwasm/ts-codegen generate command to regenerate this file.
 */

import { selectorFamily } from 'recoil'

import { Addr, WithChainId } from '@dao-dao/types'
import {
  ArrayOfVotingVault,
  Config,
  TotalPowerAtHeightResponse,
  VotingPowerAtHeightResponse,
} from '@dao-dao/types/contracts/NeutronVotingRegistry'

import {
  NeutronVotingRegistryClient,
  NeutronVotingRegistryQueryClient,
} from '../../../contracts/NeutronVotingRegistry'
import {
  refreshDaoVotingPowerAtom,
  refreshWalletBalancesIdAtom,
  signingCosmWasmClientAtom,
} from '../../atoms'
import { cosmWasmClientForChainSelector } from '../chain'
import { contractInfoSelector } from '../contract'
import { queryContractIndexerSelector } from '../indexer'

type QueryClientParams = WithChainId<{
  contractAddress: string
}>

export const queryClient = selectorFamily<
  NeutronVotingRegistryQueryClient,
  QueryClientParams
>({
  key: 'neutronVotingRegistryQueryClient',
  get:
    ({ contractAddress, chainId }) =>
    ({ get }) => {
      const client = get(cosmWasmClientForChainSelector(chainId))
      return new NeutronVotingRegistryQueryClient(client, contractAddress)
    },
  dangerouslyAllowMutability: true,
})

export type ExecuteClientParams = WithChainId<{
  contractAddress: string
  sender: string
}>

export const executeClient = selectorFamily<
  NeutronVotingRegistryClient | undefined,
  ExecuteClientParams
>({
  key: 'neutronVotingRegistryExecuteClient',
  get:
    ({ chainId, contractAddress, sender }) =>
    ({ get }) => {
      const client = get(signingCosmWasmClientAtom({ chainId }))
      if (!client) return

      return new NeutronVotingRegistryClient(client, sender, contractAddress)
    },
  dangerouslyAllowMutability: true,
})

export const daoSelector = selectorFamily<
  Addr,
  QueryClientParams & {
    params: Parameters<NeutronVotingRegistryQueryClient['dao']>
  }
>({
  key: 'neutronVotingRegistryDao',
  get:
    ({ params, ...queryClientParams }) =>
    async ({ get }) => {
      const dao = get(
        queryContractIndexerSelector({
          ...queryClientParams,
          formula: 'item',
          args: {
            key: 'dao',
          },
        })
      )
      if (dao) {
        return dao
      }

      // Fallback to chain query if indexer fails.
      const client = get(queryClient(queryClientParams))
      return await client.dao(...params)
    },
})
export const configSelector = selectorFamily<
  Config,
  QueryClientParams & {
    params: Parameters<NeutronVotingRegistryQueryClient['config']>
  }
>({
  key: 'neutronVotingRegistryConfig',
  get:
    ({ params, ...queryClientParams }) =>
    async ({ get }) => {
      const config = get(
        queryContractIndexerSelector({
          ...queryClientParams,
          formula: 'item',
          args: {
            key: 'config',
          },
        })
      )
      if (config) {
        return config
      }

      // Fallback to chain query if indexer fails.
      const client = get(queryClient(queryClientParams))
      return await client.config(...params)
    },
})
export const votingVaultsSelector = selectorFamily<
  ArrayOfVotingVault,
  QueryClientParams & {
    params: Parameters<NeutronVotingRegistryQueryClient['votingVaults']>
  }
>({
  key: 'neutronVotingRegistryVotingVaults',
  get:
    ({ params, ...queryClientParams }) =>
    async ({ get }) => {
      const client = get(queryClient(queryClientParams))
      return await client.votingVaults(...params)
    },
})
export const votingPowerAtHeightSelector = selectorFamily<
  VotingPowerAtHeightResponse,
  QueryClientParams & {
    params: Parameters<NeutronVotingRegistryQueryClient['votingPowerAtHeight']>
  }
>({
  key: 'neutronVotingRegistryVotingPowerAtHeight',
  get:
    ({ params, ...queryClientParams }) =>
    async ({ get }) => {
      get(refreshWalletBalancesIdAtom(params[0].address))

      // Don't use the indexer because different vaults have different voting
      // power sources.
      const client = get(queryClient(queryClientParams))
      return await client.votingPowerAtHeight(...params)
    },
})
export const totalPowerAtHeightSelector = selectorFamily<
  TotalPowerAtHeightResponse,
  QueryClientParams & {
    params: Parameters<NeutronVotingRegistryQueryClient['totalPowerAtHeight']>
  }
>({
  key: 'neutronVotingRegistryTotalPowerAtHeight',
  get:
    ({ params, ...queryClientParams }) =>
    async ({ get }) => {
      get(refreshWalletBalancesIdAtom(undefined))
      get(refreshDaoVotingPowerAtom(queryClientParams.contractAddress))

      // Don't use the indexer because different vaults have different voting
      // power sources.
      const client = get(queryClient(queryClientParams))
      return await client.totalPowerAtHeight(...params)
    },
})
export const infoSelector = contractInfoSelector
